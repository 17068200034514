var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-table',{attrs:{"striped":"","hover":"","items":_vm.timelineList,"fields":_vm.fields,"busy":_vm.isLoadingComponent,"show-empty":"","responsive":""},scopedSlots:_vm._u([{key:"table-busy",fn:function(){return [_c('div',{staticClass:"text-center text-secondary my-2"},[_c('b-spinner',{staticClass:"align-middle"}),_c('strong',[_vm._v("Loading...")])],1)]},proxy:true},{key:"cell(no)",fn:function(ref){
var index = ref.index;
return [_vm._v(" "+_vm._s((_vm.currentPageList - 1) * 10 + index + 1)+" ")]}},{key:"cell(registration_date)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.date_range.registration_start_date)+" to "),_c('br'),_vm._v(" "+_vm._s(item.date_range.registration_end_date)+" ")])]}},{key:"cell(selection_date)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.date_range.selection_start_date)+" to "),_c('br'),_vm._v(" "+_vm._s(item.date_range.selection_end_date)+" ")])]}},{key:"cell(announcement_date)",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(" "+_vm._s(item.date_range.announcement_start_date)+" to "),_c('br'),_vm._v(" "+_vm._s(item.date_range.announcement_end_date)+" ")])]}},{key:"cell(thumbnail)",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"image__table",attrs:{"src":item.thumbnail,"alt":""}})]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('b-dropdown',{attrs:{"variant":"danger","size":"sm"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_vm._v(" Action ")]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":'/detail-female-future-leader-timeline/' + item.uuid}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"AlertCircleIcon"}}),_vm._v(" Detail ")],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.editItem(item)}}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"EditIcon"}}),_vm._v(" Update ")],1),_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteItem(item.uuid)}}},[_c('feather-icon',{staticClass:"mr-75",attrs:{"icon":"TrashIcon"}}),_vm._v(" Delete ")],1)],1)]}}])}),_c('b-modal',{attrs:{"id":"form-ffl-timeline","centered":"","title":_vm.editIdModal != null ? 'Edit FFL Timeline' : 'Add FFL Timeline',"size":"lg","no-close-on-backdrop":"","hide-footer":""}},[_c('ValidationObserver',[_c('div',{staticClass:"form"},[_c('div',{staticClass:"form-group"},[_c('label',[_vm._v("Title:")]),_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
var classes = ref.classes;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formPayloadTimeline.title),expression:"formPayloadTimeline.title"}],staticClass:"form-control",class:classes,attrs:{"type":"text","placeholder":"Title"},domProps:{"value":(_vm.formPayloadTimeline.title)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formPayloadTimeline, "title", $event.target.value)}}}),_vm._l((_vm.validations.title),function(validation,index){return _c('small',{key:("errorName" + index),staticClass:"text-danger"},[_vm._v(_vm._s(validation))])})]}}])})],1),_c('div',{staticClass:"form-group row"},[_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('label',{attrs:{"for":"registrationStartDate"}},[_vm._v("Registration Start Date:")]),_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"registrationStartDate","config":{ dateFormat: 'Y-m-d' }},model:{value:(_vm.formPayloadTimeline.date_range.registration_start_date),callback:function ($$v) {_vm.$set(_vm.formPayloadTimeline.date_range, "registration_start_date", $$v)},expression:"formPayloadTimeline.date_range.registration_start_date"}}),_vm._l((_vm.validations[
                'date_range.registration_start_date'
              ]),function(validation,index){return _c('small',{key:("errorName" + index),staticClass:"text-danger"},[_vm._v(_vm._s(validation))])})],2),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('label',{attrs:{"for":"registrationEndDate"}},[_vm._v("Registrasion End Date:")]),_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"registrationEndDate","config":{ dateFormat: 'Y-m-d' }},model:{value:(_vm.formPayloadTimeline.date_range.registration_end_date),callback:function ($$v) {_vm.$set(_vm.formPayloadTimeline.date_range, "registration_end_date", $$v)},expression:"formPayloadTimeline.date_range.registration_end_date"}}),_vm._l((_vm.validations[
                'date_range.registration_end_date'
              ]),function(validation,index){return _c('small',{key:("errorName" + index),staticClass:"text-danger"},[_vm._v(_vm._s(validation))])})],2),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('label',{attrs:{"for":"selectionStartDate"}},[_vm._v("Selection Start Date:")]),_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"selectionStartDate","config":{ dateFormat: 'Y-m-d' }},model:{value:(_vm.formPayloadTimeline.date_range.selection_start_date),callback:function ($$v) {_vm.$set(_vm.formPayloadTimeline.date_range, "selection_start_date", $$v)},expression:"formPayloadTimeline.date_range.selection_start_date"}}),_vm._l((_vm.validations[
                'date_range.selection_start_date'
              ]),function(validation,index){return _c('small',{key:("errorName" + index),staticClass:"text-danger"},[_vm._v(_vm._s(validation))])})],2),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('label',{attrs:{"for":"selectionEndDate"}},[_vm._v("Selection End Date:")]),_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"selectionEndDate","config":{ dateFormat: 'Y-m-d' }},model:{value:(_vm.formPayloadTimeline.date_range.selection_end_date),callback:function ($$v) {_vm.$set(_vm.formPayloadTimeline.date_range, "selection_end_date", $$v)},expression:"formPayloadTimeline.date_range.selection_end_date"}}),_vm._l((_vm.validations[
                'date_range.selection_end_date'
              ]),function(validation,index){return _c('small',{key:("errorName" + index),staticClass:"text-danger"},[_vm._v(_vm._s(validation))])})],2),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('label',{attrs:{"for":"announcementStartDate"}},[_vm._v("Announcement Start Date:")]),_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"announcementStartDate","config":{ dateFormat: 'Y-m-d' }},model:{value:(_vm.formPayloadTimeline.date_range.announcement_start_date),callback:function ($$v) {_vm.$set(_vm.formPayloadTimeline.date_range, "announcement_start_date", $$v)},expression:"formPayloadTimeline.date_range.announcement_start_date"}}),_vm._l((_vm.validations[
                'date_range.announcement_start_date'
              ]),function(validation,index){return _c('small',{key:("errorName" + index),staticClass:"text-danger"},[_vm._v(_vm._s(validation))])})],2),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('label',{attrs:{"for":"announcementEndDate"}},[_vm._v("Announcement End Date:")]),_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"announcementEndDate","config":{ dateFormat: 'Y-m-d' }},model:{value:(_vm.formPayloadTimeline.date_range.announcement_end_date),callback:function ($$v) {_vm.$set(_vm.formPayloadTimeline.date_range, "announcement_end_date", $$v)},expression:"formPayloadTimeline.date_range.announcement_end_date"}}),_vm._l((_vm.validations[
                'date_range.announcement_end_date'
              ]),function(validation,index){return _c('small',{key:("errorName" + index),staticClass:"text-danger"},[_vm._v(_vm._s(validation))])})],2),_c('b-col',{attrs:{"cols":"12","lg":"6"}},[_c('label',{attrs:{"for":"thumbnail"}},[_vm._v(" Thumbnail:")]),_c('validation-provider',{attrs:{"name":"image","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
              var classes = ref.classes;
return [_c('b-form-file',{staticClass:"mb-2",class:classes,attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Choose a file or drop it here...","drop-placeholder":"Drop file here...","accept":"image/jpeg, image/png"},on:{"change":function($event){return _vm.changePhoto($event)}}}),_c('small',[_vm._v("Current file:")]),_c('br'),(_vm.photo_url_data !== null)?_c('img',{staticClass:"media_file",attrs:{"src":_vm.photo_url_data}}):_vm._e(),_vm._l((_vm.validations.thumbnail),function(validation,index){return _c('small',{key:("errorName" + index),staticClass:"text-danger"},[_vm._v(_vm._s(validation))])})]}}])})],1)],1)]),(_vm.isLoading)?_c('div',[_c('br'),_c('b-spinner',{staticClass:"mb-2",attrs:{"variant":"primary"}}),_c('br')],1):_vm._e(),(_vm.editIdModal == null)?_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isLoading},on:{"click":function($event){return _vm.createItem()}}},[_vm._v(" Save FFL Timeline ")]):_c('b-button',{attrs:{"variant":"primary","disabled":_vm.isLoading},on:{"click":function($event){return _vm.updateItem()}}},[_vm._v(" Edit FFL Timeline ")])],1)],1),(_vm.resultData.itemsPerPage > 0)?_c('div',{staticClass:"mt-3 d-flex justify-content-between align-items-center"},[_c('small',[_vm._v("Showing "+_vm._s((_vm.resultData.currentPage - 1) * 10 + 1)+" to "+_vm._s((_vm.resultData.currentPage - 1) * 10 + 1 * _vm.resultData.itemCount)+" from "+_vm._s(_vm.resultData.totalItems))]),_c('b-pagination',{staticClass:"justify-content-end",attrs:{"total-rows":_vm.resultData.totalItems,"per-page":_vm.resultData.itemsPerPage,"aria-controls":"my-table"},on:{"change":_vm.getData},model:{value:(_vm.currentPageList),callback:function ($$v) {_vm.currentPageList=$$v},expression:"currentPageList"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }